import 'numeral/locales/no';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PickerUtils } from '@ditioas/ui-pickers';
import { polyfills } from '@ditioas/util-polyfills';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dateEnLocale from 'date-fns/locale/en-US';
import dateNbLocale from 'date-fns/locale/nb';
import { useSetAtom } from 'jotai';
import numeral from 'numeral';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation, withTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { OidcProvider } from 'redux-oidc';
import { SWRConfig } from 'swr';

import { setUserLanguageAtom } from 'hooks/useCurrentUserAndSettings';
import { get } from 'libs/apiClient';
import { setGlobalVariable } from 'model/ditioGlobal';
import { defaultLocale, locales } from 'model/locale';

import App from './app';
import ErrorBoundary from './components/errors/error-boundary';
import { getUserManager } from './libs/auth';
import AlertsProvider from './providers/alerts-provider';
import MediaQueryProvider from './providers/MediaQueryProvider';
import PusherProvider from './providers/pusher-provider';

/*
  oicd init
*/
const userManager = getUserManager();

userManager.events.addUserSignedOut(() => {
  userManager.removeUser();
  userManager.signinRedirect();
});

const dateLocales = {
  nb: dateNbLocale,
  en: dateEnLocale,
};

/*
  Init i18n
 */
numeral.defaultFormat('0,0.00');
polyfills.run(defaultLocale);

const BoundaryWrapper = withTranslation()(({ t, ...rest }) => (
  <ErrorBoundary heading={t('Title.Global_error')}>
    <Content {...rest} />
  </ErrorBoundary>
));

const Content = ({ store, locale, ...rest }) => {
  const { t } = useTranslation();

  return (
    <SWRConfig
      value={{
        fetcher: (...args) =>
          get(...args).then((response) => {
            if (response?.status === 204) {
              const error = new Error(t('General.204Message'));
              error.response = response;
              throw error;
            }

            return response.json();
          }),
      }}
    >
      <OidcProvider store={store} userManager={userManager}>
        <PusherProvider>
          <AlertsProvider>
            <LocalizationProvider
              dateAdapter={PickerUtils}
              adapterLocale={dateLocales[locale]}
            >
              <BrowserRouter>
                <MediaQueryProvider>
                  <DndProvider backend={HTML5Backend}>
                    <App {...rest} locale={locale} />
                  </DndProvider>
                </MediaQueryProvider>
              </BrowserRouter>
            </LocalizationProvider>
          </AlertsProvider>
        </PusherProvider>
      </OidcProvider>
    </SWRConfig>
  );
};

Content.propTypes = {
  store: PropTypes.object.isRequired, // eslint-disable-line
  locale: PropTypes.string.isRequired,
};

const setDateAndNumberLocale = (userLocale) => {
  const locale = userLocale === locales.EN ? locales.EN : locales.NB;
  numeral.locale(locale === locales.NB ? 'no' : locale);
  setGlobalVariable('locale', locale);
};

const AppWrapper = ({ i18n, store }) => {
  const [locale, setLocale] = useState(defaultLocale);
  const setUserLanguage = useSetAtom(setUserLanguageAtom);

  useEffect(() => {
    setDateAndNumberLocale(locale);
  }, [locale]);

  const handleOnLocaleChange = (newLocale, persist = false) => {
    setLocale(newLocale);

    i18n.changeLanguage(newLocale);
    if (persist) {
      setUserLanguage(newLocale);
    }
  };

  return (
    <BoundaryWrapper
      store={store}
      onLocaleChange={handleOnLocaleChange}
      locale={locale}
    />
  );
};

AppWrapper.propTypes = {
  store: PropTypes.object.isRequired, // eslint-disable-line
  i18n: PropTypes.object.isRequired, // eslint-disable-line
};

export default withTranslation()(AppWrapper);
