import type { AutocompleteOption } from 'components/ReactHookForm/Autocomplete/Autocomplete';
import type { RHFSelectOption } from 'components/ReactHookForm/Select/Select';
import type { Company, CompanyMachine } from 'model/Company/types';
import type { File } from 'services/file-service';

// Make sure it matches Alerts.AlertStatus translation keys
export enum AlertStatus {
  Open,
  InProgress,
  Closed,
}

export enum ProjectsAlertsTabs {
  All = -1,
  Open,
  InProgress,
  Closed,
}

interface AlertOrProjectLocation {
  accuracy: number;
  coordinates: [number, number];
  isValidPoint: boolean;
  properties: unknown;
  type: 'Point';
}

export interface AlertsProject {
  active: boolean;
  inProgressCount: number;
  location: AlertOrProjectLocation;
  openCount: number;
  projectAvatarUrl: string;
  projectId: string;
  projectName: string;
  projectNumber: string;
}

export interface AlertsProjectsResponse {
  currentUserProjectNotificationCount: number;
  projects: AlertsProject[];
}

// Make sure numbers match Alerts.SeverityLevels from translations
export enum SeverityLevel {
  Trivial = -5,
  Low = 0,
  Medium = 5,
  High = 10,
  Critical = 15,
}

// Make sure numbers match Alerts.Report.DamageTypes.0 from translations
export enum InjuryType {
  NoInjury = 0,
  WithoutAbsence = 1,
  WithAbsence = 2,
}

// Make sure numbers match lerts.Report.DamageTypes.3 from translations
export enum EnvironmentalDamageType {
  NoDamage = 0,
  MinorDamage = 1,
  SeriousDamage = 2,
}

// Make sure numbers match Alerts.Report.DamageTypes.2 from translations
export enum QADamageType {
  NoDamage = 0,
  MaterialDamage = 3,
}

// Make sure numbers match Alerts.BaseTypes from translations
export enum CompanyAlertBaseType {
  HSE = 0,
  QA = 2,
  Environmental = 3,
  Machine = 4,
  Other = 1,
}

export interface SelectableOption {
  id?: string;
  name: string;
}

export interface CompanyAlertType {
  damageInfo: boolean;
  active: boolean;
  availableOnApp: boolean;
  baseType: CompanyAlertBaseType;
  categories: SelectableOption[];
  cause: SelectableOption[];
  companyId: string;
  responsible: string[];
  id: string;
  involvedParties: SelectableOption[];
  linkOnlyAvailable: boolean;
  modifiedAt: string;
  modifiedByUserId: null | string;
  name: string;
  projectId: null | string;
  publishInFeed: boolean;
  imageRequired: boolean;
  risk: boolean;
  sendEmail: boolean;
  exportable: boolean;
  serialPrefix: null | string;
  workRelatedTo: SelectableOption[];
  customCategory: SelectableOption[];
  customCategoryPropertyName: null | string;
}

export interface ProjectAlertType extends CompanyAlertType {
  damageInfoFromCompany?: boolean;
  activeFromCompany?: boolean;
  availableOnAppFromCompany?: boolean;
  hasProjectSetup: boolean;
  linkOnlyAvailableFromCompany?: boolean;
  nextSerialNumber: number;
  publishInFeedFromCompany?: boolean;
  imageRequiredFromCompany?: boolean;
  riskFromCompany?: boolean;
  sendEmailFromCompany?: boolean;
  serialPrefixFromCompany?: boolean;
  hmsRegTypeIdFromCompany?: boolean;
}

export interface HMSRegAlertType {
  id: string;
  name: string;
}

export type CompanyAlertTypeKeysWithOptions =
  | 'categories'
  | 'cause'
  | 'involvedParties'
  | 'workRelatedTo';

export enum CompanyTypeState {
  Initial,
  New,
  Deleted,
}

export interface FormCompanyAlertType
  extends Omit<
    CompanyAlertType,
    | 'id'
    | 'companyId'
    | 'responsible'
    | 'modifiedAt'
    | 'modifiedByUserId'
    | 'projectId'
  > {
  id?: CompanyAlertType['id'];
  companyId?: CompanyAlertType['companyId'];
  responsible?: CompanyAlertType['responsible'];
  modifiedAt?: CompanyAlertType['modifiedAt'];
  modifiedByUserId?: CompanyAlertType['modifiedByUserId'];
  projectId?: CompanyAlertType['projectId'];
  receiversOfCompanyNotifications: AutocompleteOption[];
}

export interface CompanySetupForm {
  types: FormCompanyAlertType[];
  receiversOfAllNotifications: (AutocompleteOption | string)[];
}

export interface NewProjectAlertType {
  damageInfo?: boolean | null;
  active: boolean | null;
  availableOnApp: boolean | null;
  linkOnlyAvailable?: boolean | null;
  modifiedAt?: string | null; // utc z
  modifiedByUserId?: string | null;
  projectId: string | null;
  projectNotificationTypeParentId: string | null;
  publishInFeed: boolean | null;
  imageRequired: boolean | null;
  risk: boolean | null;
  sendEmail?: boolean | null;
  responsible?: string[] | null;
  serialPrefix?: string | null;
  nextSerialNumber: number | null;
}

export interface DetailedProjectAlertType
  extends Omit<ProjectAlertType, 'responsible'> {
  responsible?: (AutocompleteOption | string)[];
  newNextSerialNumber: number;
}

export interface ProjectSetupForm {
  types: DetailedProjectAlertType[];
  receiversForAllAlertTypes: (AutocompleteOption | string)[];
}

interface AlertUserParam {
  companyId: string;
  companyName: string;
  companyOrgNumber?: string;
  employeeNumber?: string;
  id: string;
  name: string;
}

export enum AlertHistoryChangeType {
  Created = 0,
  Edited = 10,
  Status = 20,
  StatusAndEdit = 22,
  Assigned = 30,
  AssignedAndEdit = 32,
}

export interface AlertHistoryItem {
  userId: string;
  userName: string;
  changeDate: string;
  change: AlertHistoryChangeType;
  assignedUserId?: string;
  assignedUserName?: string;
  status?: AlertStatus;
}

interface AlertCompany {
  address: string;
  avatarSrc: string;
  companyId: string;
  contactPerson: string;
  countryCode: string;
  id: string;
  logoImageFileRef57: string;
  logoImageFileRef72: string;
  logoImageFileRef114: string;
  logoImageFileRef144: string;
  logoImageFileRefOrig: string;
  name: string;
  orgNr: string;
  phoneNumber: string;
  timeZoneId: string;
}

export interface AlertCategory {
  id: string;
  name: string;
}

export enum ProjectNotificationAccessLevel {
  NoAccess = 0,
  Read = 1,
  Write = 2,
}

interface CommonAlertFields {
  absenceDays?: number;
  assignedTo?: AlertUserParam;
  assignedToId?: string;
  baseType?: CompanyAlertBaseType;
  categories?: AlertCategory[];
  categoryId?: string;
  categoryIds?: string[];
  categoryName: string;
  causes?: SelectableOption[];
  causeId?: string;
  causeIds?: string[];
  causeName: string;
  commentInternal?: string;
  companyId: string;
  companyIds: string[];
  company: AlertCompany;
  costs: number;
  customCategories?: SelectableOption[];
  customCategoryId?: string;
  customCategoryIds?: string[];
  createdAt: string; // utc z
  createdBy: AlertUserParam;
  createdByUserId: string;
  description: string;
  externalId?: string;
  hmsRegTypeId?: SelectableOption;
  hmsRegIntegrationEnabled: boolean;
  hmsRegTypeSetOnAlertType: boolean;
  history?: AlertHistoryItem[];
  id: string;
  imageFileReferences: File['fileReference'][];
  imageIds: string[];
  involvedCompanyIds: string[];
  involvedCompanies: AlertCompany[];
  involvedParties?: SelectableOption[];
  involvedPartiesId?: string;
  involvedPartiesIds?: string;
  involvedPartiesName: string;
  involvedResources?: string;
  latitude?: number;
  locationPoint?: AlertOrProjectLocation;
  longitude?: number;
  machineName: string | null;
  machineResourceNumber: string | null;
  measures: string;
  modifiedAt: string; // utc z
  modifiedByUserId: string;
  motherCompanyName: string | null;
  projectId: string;
  projectName?: string;
  projectNumber: string;
  projectNotificationTypeId: string;
  readyForExportToExternal: boolean;
  recVersion: number;
  relatedTo?: SelectableOption[];
  relatedToId?: string;
  relatedToIds?: string[];
  relatedToName: string;
  requiresFurtherAction: boolean;
  customCategoryName?: string;
  customCategoryPropertyName?: string;
  resolvedOnLocation: boolean;
  risk: SeverityLevel;
  serialNumber?: string;
  serialNumberPrefix?: string;
  serialNumberWithPrefix?: string;
  status: AlertStatus;
  showDamageInfo: boolean;
  showRisk: boolean;
  taskId: string;
  taskName: string;
  taskNumber: string;
  title: string;
  typeName?: string;
  exportable: boolean;
  currentUserAccessLevel?: ProjectNotificationAccessLevel;
}

export type CompanyAlert = (
  | {
      baseType?: CompanyAlertBaseType.HSE;
      damageType?: InjuryType;
      machineId: null;
    }
  | {
      baseType?: CompanyAlertBaseType.Environmental;
      damageType?: EnvironmentalDamageType;
      machineId: null;
    }
  | {
      baseType?: CompanyAlertBaseType.QA;
      damageType?: QADamageType;
      machineId: null;
    }
  | {
      baseType?: CompanyAlertBaseType.Machine;
      damageType?: 0;
      machineId: string;
    }
  | {
      baseType?: CompanyAlertBaseType.Other;
      damageType?: 0;
      machineId: null;
    }
) &
  CommonAlertFields;

export type MachineAlert = {
  baseType?: CompanyAlertBaseType.Machine;
  damageType?: 0;
  machineId: string;
} & CommonAlertFields;

export interface DetailedAlert
  extends Partial<
    Omit<
      CompanyAlert,
      'involvedCompanyIds' | 'categoryIds' | 'machineID' | 'machineId'
    >
  > {
  categoryIds: RHFSelectOption[];
  involvedCompanyIds: Company[];
  machineId: CompanyMachine;
}

export interface AssignedDetails {
  notificationIds: string[];
  assignedToId: string;
}

export interface CompanySettings {
  id: string;
  companyId: string;
  recVersion: number;
  companyIds: string[];
  receiversOfAllNotifications: string[];
}

export interface ProjectSettings extends CompanySettings {
  projectId: string;
}
