/* globals FormData */

import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { mutate } from 'swr';

import type { UserFormData } from 'containers/user-handling/user-detail/PersonalInfo/UserPersonalInfoView/UserPersonalInfoView';
import type { CompanyUser } from 'containers/user-handling/user-detail/useCompanyUser';
import { useApi, usePostSWRFetcher } from 'data/base-api';
import { overviewCertificatesUrl } from 'data/certificates';
import useHandleError from 'hooks/useHandleError';
import useShowAlert from 'hooks/useShowAlert';
import { checkStatus, del, get, post, put } from 'libs/apiClient';
import { localeCompare } from 'libs/sortFunctions';
import { reduceToObjectById } from 'libs/utils';
import type { ProjectSettings } from 'model/Alerts/types';
import type {
  Employment,
  EmploymentValidationResponse,
} from 'model/Company/types';
import type {
  AccessibleUser,
  BestMatchingProfile,
  Certificate,
  ChangesHistory,
  DetailedEmployeeAndSubcontractorUser,
  EmployeeAndSubcontractorUser,
  NewCertificate,
  NewEmployment,
  NewUserData,
  SMS,
  Tag,
  User,
  UserExistsCheckByName,
  UserExistsCheckByPhoneNumber,
  UserExistsCheckResponse,
  UserForSearch,
  UserProjectOverview,
  UserV3Api,
} from 'model/User/types';

const baseUrl = '/api/v4/users';
const legacyBaseUrl = '/api/userlegacy';
const appBaseUrl = '/api/user'; // TODO: agree on a final place for tags history api
const certificatesBaseUrl = '/api/v4/user-certificate';
const baseUrlV3 = '/api/v3/user';

export const useUsersRaw = (
  ignoreUserFiltering = false,
  fetchInactiveUsers = false,
  revalidateOnMount = false
) => {
  const handleError = useHandleError();

  const url = `/api/v4/users?ignoreUserFiltering=${ignoreUserFiltering}&activeInactiveAll=${fetchInactiveUsers ? 2 : 0}`;

  const result = useApi<EmployeeAndSubcontractorUser[]>(url, {
    revalidateOnFocus: false,
    revalidateOnMount,
  });

  useEffect(() => {
    if (result.error) {
      handleError(result.error);
    }
  }, [result.error]);

  return result;
};

export const useGetUsers = (
  revalidateOnMount = false,
  includeInactive = false
) => {
  const {
    data,
    error,
    mutate: activeMutate,
    ...rest
  } = useUsersRaw(false, includeInactive, revalidateOnMount);

  const activeUsers = useMemo(
    () =>
      data
        ?.sort((a, b) => localeCompare(a.fullName, b.fullName))
        .filter((user) => !user.isDisabled) || [],
    [data]
  );

  const activeUsersById = useMemo(
    () => (activeUsers ? reduceToObjectById(activeUsers) : undefined),
    [activeUsers]
  );

  const usersById = useMemo(
    () =>
      data
        ? reduceToObjectById(
            data.sort((a, b) => localeCompare(a.fullName, b.fullName))
          )
        : undefined,
    [data]
  );

  return {
    users: data,
    activeUsers,
    usersById,
    activeUsersById,
    error,
    isFetching: !data && !error,
    ...rest,
  };
};

export const useGetSoonToBeOrActiveUsers = (revalidateOnMount = false) => {
  const { users, isFetching, ...rest } = useGetUsers(revalidateOnMount, true);

  const soonToBeOrActiveUsers = useMemo(
    () =>
      users
        ?.sort((a, b) => localeCompare(a.fullName, b.fullName))
        .filter(
          (user) => !user.isDisabled || user.employment?.isFutureEmployment
        ) || [],
    [users]
  );

  const soonToBeOrActiveUsersById = useMemo(
    () => (users ? reduceToObjectById(soonToBeOrActiveUsers) : undefined),
    [soonToBeOrActiveUsers]
  );

  return {
    users,
    soonToBeOrActiveUsers,
    soonToBeOrActiveUsersById,
    isFetching,
    ...rest,
  };
};

export const useGetActiveUsersSorted = (
  revalidateOnMount = false,
  ignoreUserFiltering = false
) => {
  const { data, error } = useUsersRaw(
    ignoreUserFiltering,
    false,
    revalidateOnMount
  );

  const sortedActiveUsers = useMemo(
    () => data?.sort((a, b) => localeCompare(a.fullName, b.fullName)) || [],
    [data]
  );

  return {
    isFetching: !data && !error,
    sortedActiveUsers,
  };
};

export const getEmployeesAndSubcontractors = async (): Promise<
  EmployeeAndSubcontractorUser[]
> => {
  const response = await get(baseUrl);

  return response.json();
};

export const useGetCompanyUser = (
  companyProfileId: string,
  revalidateOnMount = true
) => {
  const response = useApi<CompanyUser>(`${baseUrl}/${companyProfileId}`, {
    revalidateOnMount,
  });

  return response;
};

export const getEmployeesAndSubcontractorsById = async (
  companyProfileId: string
): Promise<UserForSearch> => {
  const response = await get(`${baseUrl}/${companyProfileId}`);

  return response.json();
};

export const getSmsLog = async (userId: string): Promise<SMS[]> => {
  const response = await get(`${legacyBaseUrl}/${userId}/smslog`);
  return response.json();
};

export const useGetSmsHistory = (userId: string) => {
  const { data, ...rest } = useApi<SMS[]>(`${legacyBaseUrl}/${userId}/smslog`);

  return {
    smsHistory: data,
    ...rest,
  };
};

export const uploadUserAvatar = async (
  file: Blob,
  companyProfileId: string = null
): Promise<string> => {
  const data = new FormData();
  data.append('file', file);
  let fileHeaders: { CollectionRef: string; IdRef: string } = null;

  if (companyProfileId) {
    fileHeaders = {
      CollectionRef: 'UserCompanyProfiles',
      IdRef: companyProfileId,
    };
  }
  const response = checkStatus(
    await post(`${baseUrl}/upload-avatar`, data, fileHeaders, true)
  );

  return response.json();
};

export const changePhoneNumber = async (
  companyProfileId: string,
  newPhoneNumber: string
): Promise<DetailedEmployeeAndSubcontractorUser> => {
  const response = checkStatus(
    await post(
      `${baseUrl}/${companyProfileId}/change-phone-number`,
      newPhoneNumber
    )
  );

  return response.json();
};

export const updatePersonalInfo = async (
  personalInfo: Omit<UserFormData, 'projectSettings' | 'defaultResource'>
): Promise<DetailedEmployeeAndSubcontractorUser> => {
  const response = checkStatus(
    await put(`${baseUrl}/${personalInfo.companyProfileId}`, personalInfo)
  );

  return response.json();
};

export const createUser = async (
  userRequest: NewUserData
): Promise<DetailedEmployeeAndSubcontractorUser> => {
  const response = checkStatus(await post(baseUrl, userRequest));

  return response.json();
};

export const searchForUser = async (
  searchString: string,
  globalSearch = true,
  onlyEmployees = false,
  accessLevel: number = null,
  companyId: string = null
): Promise<UserForSearch[]> => {
  const response = checkStatus(
    await get(
      `${baseUrl}/search/?query=${encodeURIComponent(
        searchString
      )}&globalSearch=${globalSearch}${
        accessLevel ? `&accessLevelFilter=${accessLevel}` : ''
      }${companyId ? `&companyId=${companyId}` : ''}${
        onlyEmployees ? '&onlyEmployees=true' : ''
      }`
    )
  );

  return response.json();
};

export const singleCompanySearchForUser = async (
  searchString: string
): Promise<UserForSearch[]> => searchForUser(searchString, false);

export const verifyDelete = async (
  companyProfileId: string
): Promise<{
  isDeletable: boolean;
  validationErrors: [];
}> => {
  const response = checkStatus(
    await get(`${baseUrl}/${companyProfileId}/verify-delete`)
  );

  return response.json();
};

export const deleteUser = async (
  companyProfileId: string
): Promise<{ success: boolean }> => {
  const response = checkStatus(await del(`${baseUrl}/${companyProfileId}`));

  return response.json();
};

export const sendWelcomeSms = async (companyProfileId: string) => {
  const response = checkStatus(
    await post(`${legacyBaseUrl}/sendapplink/${companyProfileId}`)
  );

  return response.json();
};

export const getTagsHistory = async (userId: string): Promise<Tag> => {
  const response = await get(
    `${appBaseUrl}/user-tags-tracked-changes?userId=${userId}`
  );
  const parsed = await response.json();

  return parsed;
};

export const useGetTagsHistory = (userId: string) => {
  const { data, ...rest } = useApi<Tag[]>(
    userId ? `${appBaseUrl}/user-tags-tracked-changes?userId=${userId}` : null
  );

  return {
    tagHistory: data,
    ...rest,
  };
};

export const useGetProfileHistory = (userId: string) => {
  const { data, ...rest } = useApi<ChangesHistory[]>(
    userId ? `${appBaseUrl}/${userId}/company-profile-change-log` : null
  );

  return { profileHistory: data, ...rest };
};

export const useGetEmploymentHistory = (userId: string) => {
  const { data, ...rest } = useApi<ChangesHistory[]>(
    userId ? `${appBaseUrl}/${userId}/employment-change-log` : null
  );

  return { employmentHistory: data, ...rest };
};

export const resetOfflineData = async (companyProfileId: string) => {
  const response = await checkStatus(
    await post(`${legacyBaseUrl}/sendappofflineresetpush/${companyProfileId}`)
  );
  return response.json();
};

export const resetAllData = async (companyProfileId: string) => {
  const response = await checkStatus(
    await post(`${legacyBaseUrl}/sendappfullresetpush/${companyProfileId}`)
  );
  return response.json();
};

export const requestDiagnosticData = async (companyProfileId: string) => {
  const response = await checkStatus(
    await post(`${legacyBaseUrl}/send-diagnostics-data/${companyProfileId}`)
  );
  return response.json();
};

export const createSupportUser = async (companyId: string) => {
  const response = await checkStatus(
    await post(`${baseUrl}/create-support-user/${companyId}`)
  );
  return response.json();
};

export const downloadUsers = async (): Promise<Blob> => {
  const response = await get(`${baseUrl}/download`);
  return response.blob();
};

export const useGetUsersCertificates = (
  userIds: string[],
  revalidateOnMount = true
) => {
  const { data, ...rest } = usePostSWRFetcher<Certificate[], string[]>(
    userIds ? `${certificatesBaseUrl}/fetch-for-users` : null,
    userIds,
    { revalidateOnMount }
  );

  const certificateTypes: string[] = React.useMemo(() => {
    let types = [];
    data?.forEach(({ typeId }) => {
      if (typeId && !types.includes(typeId)) {
        types.push(typeId);
      }
    }, []);
    types = types.sort((a, b) =>
      localeCompare(a.trim().toLocaleLowerCase(), b.trim().toLocaleLowerCase())
    );

    return types;
  }, [data]);

  return {
    certificates: data,
    certificateTypes,
    ...rest,
  };
};

export const useGetAccessibleUsers = () => {
  const { data, ...rest } = useApi<AccessibleUser[]>('api/user/lookuplist/all');

  return {
    accesibleUsers: data,
    ...rest,
  };
};

export const changeCompanyProfile = async (companyProfileId: string) => {
  const response = await checkStatus(
    await put(`${baseUrlV3}/changecompanyprofile/${companyProfileId}`)
  );
  return response.json();
};

export const useGetUserCertificates = (userId: string) => {
  const { data, ...rest } = useApi<Certificate[]>(
    userId ? `${certificatesBaseUrl}/${userId}` : null
  );

  return {
    userCertificates: data,
    ...rest,
  };
};

export const useCreateUserCertificate = () => {
  const { t } = useTranslation();
  const handleError = useHandleError();
  const { showSuccessMessage } = useShowAlert();

  return async (
    userId: string,
    newCertificate: NewCertificate
  ): Promise<Certificate | null> => {
    try {
      const response = await checkStatus(
        await post(`${certificatesBaseUrl}/${userId}`, newCertificate)
      );
      mutate(overviewCertificatesUrl);
      showSuccessMessage({
        message: t('User.Certificates.AddCertificate_SuccessMessage'),
      });
      const parsed = await response.json();

      return parsed;
    } catch (e) {
      handleError(e);
      return null;
    }
  };
};

export const useUpdateUserCertificate = () => {
  const { t } = useTranslation();
  const handleError = useHandleError();
  const { showSuccessMessage } = useShowAlert();

  return async (
    userId: string,
    newCertificate: NewCertificate
  ): Promise<Certificate | null> => {
    try {
      const response = await checkStatus(
        await put(`${certificatesBaseUrl}/${userId}`, newCertificate)
      );
      mutate(overviewCertificatesUrl);
      showSuccessMessage({
        message: t('User.Certificates.EditCertificate_SuccessMessage'),
      });
      const parsed = await response.json();

      return parsed;
    } catch (e) {
      handleError(e);
      return null;
    }
  };
};

export const useGetProjectSettingsForUsers = (projectId: string | null) =>
  useApi<ProjectSettings>(
    projectId && `${baseUrl}/project-settings/${projectId}`
  );

export const useGetUserProjectsOverview = (
  userId: string | null,
  includeInactive = false
) =>
  useApi<UserProjectOverview[]>(
    userId &&
      `${baseUrl}/project-user-setting/${userId}?includeInactive=${includeInactive}`
  );

export const useGetUserProjectSetup = (
  userId: string | null,
  projectId: string | null
) =>
  useApi<ProjectSettings>(
    userId &&
      projectId &&
      `${baseUrl}/project-user-setting/${userId}/${projectId}?resourceFilter=2`
  );

export const searchForUsers = async (
  searchString: string
): Promise<UserV3Api[]> => {
  if (!searchString.length) {
    return [];
  }

  const response = checkStatus(
    await get(
      `${appBaseUrl}/usersearch?searchString=${searchString}&activeOnly=true`
    )
  );

  return response.json();
};

export const useGetUserById = (id: string | null | undefined) =>
  useApi<User>(id ? `${baseUrlV3}/${id}` : null);

export const useCheckIfPhoneNumberExists = () => {
  const handleError = useHandleError();

  return async (
    request: UserExistsCheckByPhoneNumber | UserExistsCheckByName
  ) => {
    try {
      const response = checkStatus(
        await post(`${baseUrl}/phone-number-exists`, request)
      );
      const parsed: UserExistsCheckResponse | null = await response.json();

      return parsed;
    } catch (e) {
      handleError(e);
      return null;
    }
  };
};

export const useValidateEmployments = () => {
  const handleError = useHandleError();

  return async (
    userId: string,
    employments: { startDate: string; endDate: string }[]
  ) => {
    try {
      const response = checkStatus(
        await post(`${baseUrl}/employment-validation`, { userId, employments })
      );
      const parsed: EmploymentValidationResponse = await response.json();

      return parsed;
    } catch (e) {
      handleError(e);
      return e;
    }
  };
};

export const useSaveEmployments = () => {
  const handleError = useHandleError();

  return async (
    employments: NewEmployment[],
    companyProfileId: string,
    callBack: (message: string) => void
  ) => {
    try {
      const response = checkStatus(
        await put(`${baseUrl}/employments`, employments)
      );
      mutate(`${baseUrl}/${companyProfileId}`);
      const parsed: Employment[] = await response.json();
      return parsed;
    } catch (e) {
      handleError(e, undefined, ({ message }) => callBack(message));
      return e;
    }
  };
};

export const useGetBestMatchingUserProfile = (
  userId: string,
  revalidateOnMount = true
) =>
  useApi<BestMatchingProfile>(
    userId ? `${baseUrl}/best-matching-user-profile/${userId}` : null,
    {
      revalidateOnMount,
    }
  );

export const useGetBestMatchingUserProfileOnce = () => {
  const handleError = useHandleError();

  return async (userId: string) => {
    try {
      const response = checkStatus(
        await get(`${baseUrl}/best-matching-user-profile/${userId}`)
      );

      const parsed: BestMatchingProfile = await response.json();
      return parsed;
    } catch (e) {
      handleError(e);
      return null;
    }
  };
};
