import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { MassTransportApi, ReportApi } from '../../libs/apiClient';
import { TripLiveData, TripLogListItem } from '../../model';
import { onError, runWithTryCatch, withErrorHandling } from '../helpers';
import * as actions from './reportsActions';
import * as types from './reportsTypes';

const getTripLogs = (companyId, from, to, filterGeofence, downloadFile) => {
  const f = encodeURIComponent(from);
  const t = encodeURIComponent(to);
  let url = `api/mass-transport-report/?companyId=${companyId}&fromDate=${f}&toDate=${t}`;
  let applyGeofence = false;
  if (filterGeofence && filterGeofence.length > 0) {
    applyGeofence = true;
    url =
      url = `api/mass-transport-report/trips-in-polygon/?companyId=${companyId}&fromDate=${f}&toDate=${t}`;
  }

  if (downloadFile) {
    url += `&downloadFile=true`;
    if (!applyGeofence) {
      return withErrorHandling(
        ReportApi.get(url).then((result) => result.blob())
      );
    }
    return withErrorHandling(
      ReportApi.post(url, { coordinates: filterGeofence }).then((result) =>
        result.blob()
      )
    );
  }
  if (applyGeofence) {
    return withErrorHandling(
      ReportApi.post(url, { coordinates: filterGeofence }).then((result) =>
        result.json()
      )
    );
  }
  return withErrorHandling(ReportApi.get(url).then((result) => result.json()));
};

const parseTripLogs = (data) => {
  let out = [];
  if (data) {
    out = data.map((d) => TripLogListItem.fromObject(d));
  }
  return out;
};

function* fetchTripLogs(params) {
  try {
    const data = yield call(
      getTripLogs,
      params.companyId,
      params.fromDate,
      params.toDate,
      params.filterGeofence,
      params.downloadFile
    );
    if (!(data instanceof Blob)) {
      const tripLogs = runWithTryCatch(
        parseTripLogs,
        data,
        'Failed to parse response from server',
        types.GET_TRIP_LOGS
      );
      yield put(actions.getTripLogsSuccessAction(tripLogs));
    } else {
      yield put(
        actions.exportTripLogsSuccessAction({
          data,
          fromDate: params.fromDate,
          toDate: params.toDate,
        })
      );
    }
  } catch (error) {
    yield put(yield call(onError, error));
  }
}

const getTripTracking = (tripId) => {
  const url = `/api/trip-tracking/track-full-path/${tripId}`;
  return withErrorHandling(
    MassTransportApi.get(url).then((result) => result.json())
  );
};

const parseTripTracking = (data) => data;

function* fetchTripTracking(params) {
  try {
    const data = yield call(getTripTracking, params.tripId);
    const tripTracking = runWithTryCatch(
      parseTripTracking,
      data,
      'Failed to parse response from server',
      types.GET_TRIP_TRACKING
    );
    yield put(actions.getTripTrackingSuccessAction(tripTracking));
  } catch (error) {
    yield put(yield call(onError, error));
  }
}

const getFilteredTripLogs = (filters) => {
  const url = `api/mass-transport-report/mass-transport-report`;
  if (filters.downloadFile) {
    return withErrorHandling(
      ReportApi.post(url, filters).then((result) => result.blob())
    );
  }
  return withErrorHandling(
    ReportApi.post(url, filters).then((result) => result.json())
  );
};

function* fetchFilteredTripLogs(params) {
  try {
    const data = yield call(getFilteredTripLogs, params.filters);
    if (!(data instanceof Blob)) {
      const tripLogs = runWithTryCatch(
        parseTripLogs,
        data,
        'Failed to parse response from server',
        types.GET_FILTERED_TRIP_LOGS
      );
      yield put(actions.getTripLogsSuccessAction(tripLogs));
    } else {
      yield put(
        actions.exportTripLogsSuccessAction({
          data,
          fromDate: params.filters.fromDate,
          toDate: params.filters.toDate,
        })
      );
    }
  } catch (error) {
    yield put(yield call(onError, error));
  }
}

const getLiveTrips = (companyId, projectId) => {
  const url = `/api/live/${companyId}/${
    projectId ? `?projectIdList=${projectId}` : ''
  }`;
  return withErrorHandling(ReportApi.get(url).then((result) => result.json()));
};

const parseLiveTrips = (data) => {
  let out = new TripLiveData();
  if (data) {
    out = TripLiveData.fromObject(data);
  }
  return out;
};

function* fetchLiveTrips(params) {
  try {
    const data = yield call(getLiveTrips, params.companyId, params.projectId);
    const liveData = runWithTryCatch(
      parseLiveTrips,
      data,
      'Failed to parse response from server',
      types.GET_LIVE_TRIPS
    );
    yield put(actions.getLiveTripsSuccessAction(liveData));
  } catch (error) {
    yield put(yield call(onError, error));
  }
}

const getExportScale = (companyId, from, to) => {
  const f = encodeURIComponent(from);
  const t = encodeURIComponent(to);
  const url = `/api/mass-transport-report/online-scale-export/?companyId=${companyId}&fromDate=${f}&toDate=${t}`;

  return withErrorHandling(ReportApi.get(url).then((result) => result.blob()));
};

function* exportScale(params) {
  try {
    const data = yield call(
      getExportScale,
      params.companyId,
      params.fromDate,
      params.toDate
    );
    yield put(
      actions.exportTripLogsSuccessAction({
        data,
        fileName: 'ReportForScale_',
        fromDate: params.fromDate,
        toDate: params.toDate,
      })
    );
  } catch (error) {
    yield put(yield call(onError, error));
  }
}

export default function* reportsSagas() {
  yield all([
    takeEvery(types.GET_TRIP_LOGS, fetchTripLogs),
    takeEvery(types.GET_FILTERED_TRIP_LOGS, fetchFilteredTripLogs),
    takeEvery(types.GET_TRIP_TRACKING, fetchTripTracking),
    takeEvery(types.GET_LIVE_TRIPS, fetchLiveTrips),
    takeEvery(types.EXPORT_SCALE, exportScale),
  ]);
}
