import React from 'react';
import { endOfDay, formatISO, startOfDay } from 'date-fns';
import debounce from 'lodash.debounce';
import type { Range } from 'react-date-range';
import type { Control } from 'react-hook-form';
import { useWatch } from 'react-hook-form';

import type {
  AlertsFiltersForm,
  ProjectsAlertsProps,
} from 'containers/Alerts/ProjectsAlerts/types';
import { useApi, usePostSWRFetcher } from 'data/base-api';
import useHandleError from 'hooks/useHandleError';
import { checkStatus, del, patch, post, put } from 'libs/apiClient';
import type {
  AlertsProjectsResponse,
  AlertStatus,
  AssignedDetails,
  CompanyAlert,
  CompanyAlertType,
  CompanySettings,
  FormCompanyAlertType,
  HMSRegAlertType,
  MachineAlert,
  NewProjectAlertType,
  ProjectAlertType,
  ProjectSettings,
  SeverityLevel,
} from 'model/Alerts/types';
import { ProjectsAlertsTabs } from 'model/Alerts/types';

const baseUrl = '/api/v2/proj-notifications';

export const useGetAlertsProjects = () =>
  useApi<AlertsProjectsResponse>(`${baseUrl}/projects`);

export const useGetCompanyAlertTypes = (revalidateOnMount = true) => {
  const { data: companyAlertTypes, ...rest } = useApi<CompanyAlertType[]>(
    `${baseUrl}/types-company`,
    { revalidateOnMount }
  );

  return { companyAlertTypes, ...rest };
};

export const useGetHMSRegAlertTypes = (
  customerId: string,
  callApi: boolean
) => {
  const { data: hmsRegAlertTypes, ...rest } = usePostSWRFetcher<
    HMSRegAlertType[]
  >(callApi ? `${baseUrl}/notifications/external-types` : null, {
    CustomerID: customerId,
  });
  return { hmsRegAlertTypes, ...rest };
};

export const useCreateCompanyAlertType = () => {
  const handleError = useHandleError();

  return async (
    newCompanyAlertType: FormCompanyAlertType
  ): Promise<CompanyAlertType | null> => {
    try {
      const response = checkStatus(
        await post(`${baseUrl}/types`, newCompanyAlertType)
      );

      const parsed = await response.json();
      return parsed;
    } catch (e) {
      handleError(e);
      return null;
    }
  };
};

export const useUpdateCompanyAlertType = () => {
  const handleError = useHandleError();

  return async (
    newCompanyAlertType: FormCompanyAlertType
  ): Promise<CompanyAlertType | null> => {
    try {
      const response = checkStatus(
        await put(`${baseUrl}/types`, newCompanyAlertType)
      );

      const parsed = await response.json();
      return parsed;
    } catch (e) {
      handleError(e);
      return null;
    }
  };
};

export const useDeleteCompanyAlertType = () => {
  const handleError = useHandleError();

  return async (companyTypeId: string): Promise<void> => {
    try {
      checkStatus(await del(`${baseUrl}/types/${companyTypeId}`));
    } catch (e) {
      handleError(e);
      throw new Error();
    }
  };
};

export const useGetCompanySettings = () => {
  const { data: companySettings, ...rest } = useApi<CompanySettings>(
    `${baseUrl}/company-settings`,
    { errorRetryCount: 0 },
    true
  );

  return { companySettings, ...rest };
};

export const useCreateCompanySettings = () => {
  const handleError = useHandleError();

  return async (
    newCompanySettings: Pick<CompanySettings, 'receiversOfAllNotifications'>
  ): Promise<CompanySettings | null> => {
    try {
      const response = checkStatus(
        await post(`${baseUrl}/company-settings`, newCompanySettings)
      );

      const parsed = await response.json();
      return parsed;
    } catch (e) {
      handleError(e);
      return null;
    }
  };
};

export const useUpdateCompanySettings = () => {
  const handleError = useHandleError();

  return async (
    newCompanySettings: CompanySettings
  ): Promise<CompanySettings | null> => {
    try {
      const response = checkStatus(
        await patch(`${baseUrl}/company-settings`, newCompanySettings)
      );

      const parsed = await response.json();
      return parsed;
    } catch (e) {
      handleError(e);
      return null;
    }
  };
};

export const useGetProjectAlertTypes = (
  projectId: string,
  includeInactive = true,
  revalidateOnMount = true
) => {
  const { data: projectAlertTypes, ...rest } = useApi<ProjectAlertType[]>(
    projectId
      ? `${baseUrl}/types/${projectId}?includeInactive=${includeInactive}&includeTypesNotAvailableOnApp=true`
      : null,
    { revalidateOnMount },
    true
  );

  return { projectAlertTypes, ...rest };
};

export const useCreateProjectAlertType = () => {
  const handleError = useHandleError();

  return async (
    newProjectAlertType: NewProjectAlertType
  ): Promise<Omit<
    NewProjectAlertType,
    'projectNotificationTypeParentId'
  > | null> => {
    try {
      const response = checkStatus(
        await post(`${baseUrl}/types-project`, newProjectAlertType)
      );

      const parsed = await response.json();
      return parsed;
    } catch (e) {
      handleError(e);
      return null;
    }
  };
};

export const useUpdateProjectAlertType = () => {
  const handleError = useHandleError();

  return async (
    newProjectAlertType: NewProjectAlertType
  ): Promise<Omit<
    NewProjectAlertType,
    'projectNotificationTypeParentId'
  > | null> => {
    try {
      const response = checkStatus(
        await put(`${baseUrl}/types-project`, newProjectAlertType)
      );

      const parsed = await response.json();
      return parsed;
    } catch (e) {
      handleError(e);
      return null;
    }
  };
};

interface Filters {
  fromDate?: string;
  toDate?: string;
  projectIds?: string[];
  risk?: SeverityLevel;
  typeId?: string;
  assignedToId?: string;
  createdById?: string;
  categoryId?: string;
  relatedToId?: string;
  causeId?: string;
  involvedPartiesId?: string;
  requiresFurtherAction?: boolean;
  machineId?: string;
}

const useFiltersForRequest = (
  filters: Partial<AlertsFiltersForm>,
  alertsForProjects = false,
  machineId: ProjectsAlertsProps['machineId'] = ''
) => {
  const [filtersForRequest, setFiltersForRequest] = React.useState<Filters>(
    machineId
      ? {
          machineId,
        }
      : {}
  );
  const debouncedFilterUpdate = React.useMemo(
    () =>
      debounce((newFilters: Partial<AlertsFiltersForm>) => {
        const usedFilters: Filters = {};
        Object.entries(newFilters).forEach(([key, value]) => {
          if (!value) {
            return;
          }

          switch (key) {
            case 'dateRange': {
              const range = value as Range;
              if (range.startDate && range.endDate) {
                usedFilters.fromDate = formatISO(startOfDay(range.startDate));
                usedFilters.toDate = formatISO(endOfDay(range.endDate));
              }
              break;
            }
            case 'project':
              if (!alertsForProjects) {
                usedFilters.projectIds = [
                  (value as AlertsFiltersForm[typeof key]).projectId,
                ];
              }
              break;
            case 'assignedTo':
              usedFilters.assignedToId = (
                value as AlertsFiltersForm[typeof key]
              ).id;
              break;
            case 'createdBy':
              usedFilters.createdById = (
                value as AlertsFiltersForm[typeof key]
              ).id;
              break;
            case 'risk':
              if (value !== -1) {
                usedFilters.risk = value as SeverityLevel;
              }
              break;
            case 'needsFollowUp':
              usedFilters.requiresFurtherAction =
                value as AlertsFiltersForm[typeof key];
              break;
            case 'companyAlertType':
              usedFilters.typeId = (value as AlertsFiltersForm[typeof key]).id;
              break;
            case 'baseType':
              usedFilters[key] = value;
              break;
            case 'machineId':
              usedFilters[key] =
                machineId || (value as AlertsFiltersForm[typeof key]);
              break;
            default:
              usedFilters[key] = (value as { id: string }).id;
              break;
          }

          setFiltersForRequest(usedFilters);
        });
      }, 150),
    []
  );

  React.useEffect(() => {
    debouncedFilterUpdate(filters);
  }, [filters]);

  return filtersForRequest;
};

export const useGetMultipleProjectsAlerts = (
  control: Control<AlertsFiltersForm, unknown>,
  projectIds?: string,
  status?: ProjectsAlertsTabs | AlertStatus,
  onlyAssignedToCurrentUser = false
) => {
  const filters = useWatch({ control }) as Partial<AlertsFiltersForm>;
  const filtersForRequest = useFiltersForRequest(filters, true);

  const { data: alerts, ...rest } = usePostSWRFetcher<CompanyAlert[]>(
    projectIds ? `${baseUrl}/notifications/list` : null,
    projectIds &&
      (status !== ProjectsAlertsTabs.All
        ? {
            projectIds: projectIds.split(','),
            status,
            onlyAssignedToCurrentUser,
            ...filtersForRequest,
          }
        : {
            projectIds: projectIds.split(','),
            onlyAssignedToCurrentUser,
            ...filtersForRequest,
          })
  );

  return { alerts, ...rest };
};

export const useGetProjectSettings = (projectId: string) => {
  const { data: projectSettings, ...rest } = useApi<ProjectSettings>(
    projectId ? `${baseUrl}/project-settings/${projectId}` : null,
    { errorRetryCount: 0 },
    true
  );

  return { projectSettings, ...rest };
};

export const useCreateProjectSettings = () => {
  const handleError = useHandleError();

  return async (
    newProjectSettings: Pick<
      ProjectSettings,
      'projectId' | 'receiversOfAllNotifications'
    >
  ): Promise<ProjectSettings | null> => {
    try {
      const response = checkStatus(
        await post(`${baseUrl}/project-settings`, newProjectSettings)
      );

      const parsed = await response.json();
      return parsed;
    } catch (e) {
      handleError(e);
      return null;
    }
  };
};

export const useUpdateProjectSettings = () => {
  const handleError = useHandleError();

  return async (
    newProjectSettings: ProjectSettings
  ): Promise<ProjectSettings | null> => {
    try {
      const response = checkStatus(
        await patch(`${baseUrl}/project-settings`, newProjectSettings)
      );

      const parsed = await response.json();
      return parsed;
    } catch (e) {
      handleError(e);
      return null;
    }
  };
};

export const useGetCompanyOrUserAlerts = (
  control: Control<AlertsFiltersForm, unknown>,
  status?: ProjectsAlertsTabs | AlertStatus,
  onlyAssignedToCurrentUser?: boolean,
  skip?: boolean
) => {
  const filters = useWatch({ control }) as Partial<AlertsFiltersForm>;
  const filtersForRequest = useFiltersForRequest(filters);

  const { data: alerts, ...rest } = usePostSWRFetcher<CompanyAlert[]>(
    !skip ? `${baseUrl}/notifications/list` : null,
    status !== ProjectsAlertsTabs.All
      ? {
          status,
          onlyAssignedToCurrentUser,
          ...filtersForRequest,
        }
      : {
          onlyAssignedToCurrentUser,
          ...filtersForRequest,
        }
  );

  return { alerts, ...rest };
};

export const useGetMachineAlerts = (
  control: Control<AlertsFiltersForm, unknown>,
  machineId: ProjectsAlertsProps['machineId'],
  status?: ProjectsAlertsTabs | AlertStatus,
  onlyAssignedToCurrentUser?: boolean,
  skip?: boolean
) => {
  const filters = useWatch({ control }) as Partial<AlertsFiltersForm>;
  const filtersForRequest = useFiltersForRequest(filters, undefined, machineId);

  const { data: alerts, ...rest } = usePostSWRFetcher<MachineAlert[]>(
    !skip ? `${baseUrl}/notifications/machines` : null,
    status !== ProjectsAlertsTabs.All
      ? {
          status,
          onlyAssignedToCurrentUser,
          ...filtersForRequest,
        }
      : {
          onlyAssignedToCurrentUser,
          ...filtersForRequest,
        }
  );

  return { alerts, ...rest };
};

export const useCreateAlert = () => {
  const handleError = useHandleError();

  return async (
    newAlert: Partial<CompanyAlert>
  ): Promise<CompanyAlert | null> => {
    try {
      const response = checkStatus(
        await post(`${baseUrl}/notifications`, newAlert)
      );

      const parsed = await response.json();
      return parsed;
    } catch (e) {
      handleError(e);
      return null;
    }
  };
};

export const useUpdateAlert = () => {
  const handleError = useHandleError();

  return async (newAlert: CompanyAlert): Promise<CompanyAlert | null> => {
    try {
      const response = checkStatus(
        await put(`${baseUrl}/notifications`, newAlert)
      );

      const parsed = await response.json();
      return parsed;
    } catch (e) {
      handleError(e);
      return null;
    }
  };
};

export const useDeleteAlert = () => {
  const handleError = useHandleError();

  return async (alertId: string): Promise<void> => {
    try {
      checkStatus(await del(`${baseUrl}/notification/${alertId}`));
    } catch (e) {
      handleError(e);
    }
  };
};

export const useBulkDeleteAlerts = () => {
  const handleError = useHandleError();

  return async (notificationIds: string[]): Promise<void> => {
    try {
      checkStatus(
        await del(`${baseUrl}/notifications/bulk-delete`, { notificationIds })
      );
    } catch (e) {
      handleError(e);
    }
  };
};

export const useGetAlertById = (alertId?: string, skip = false) => {
  const { data, ...rest } = useApi<CompanyAlert>(
    alertId && !skip ? `${baseUrl}/notification/${alertId}` : null
  );

  return {
    alert: data,
    ...rest,
  };
};

export const useGetPublicAlertById = (
  alertId: string,
  xai: string,
  xat: string
) => {
  const params = new URLSearchParams();
  params.set('xai', xai);
  params.set('xat', xat);

  const { data, ...rest } = useApi<CompanyAlert>(
    alertId && xai && xat
      ? `${baseUrl}/public-notification/${alertId}?${params}`
      : null
  );

  return {
    alert: data,
    ...rest,
  };
};

interface AssignResponse {
  currentUserHasAccessToAllNotifications: boolean;
  notificationIds: string[];
}

export const useAssignAlert = () => {
  const handleError = useHandleError();

  return async (newAssignedTo: AssignedDetails): Promise<AssignResponse> => {
    try {
      const response = checkStatus(
        await post(`${baseUrl}/notifications/assign`, newAssignedTo)
      );

      return await response.json();
    } catch (e) {
      handleError(e);
      return null;
    }
  };
};

export const downloadAlertsCSV = async (
  projectIds: string[],
  notificationIds: string[],
  fromDate?: string | null,
  toDate?: string | null,
  userId?: string
): Promise<Blob> => {
  const response = await post(`${baseUrl}/notifications/download`, {
    notificationIds,
    fromDate: fromDate?.length ? fromDate : undefined,
    toDate: toDate?.length ? toDate : undefined,
    ...(userId ? { assignedToId: userId } : { projectIds }),
  });
  return response.blob();
};

export const useSetReadyForIntegration = () => {
  const handleError = useHandleError();

  return async (alertIds: string[], hmsRegType: string | null) => {
    try {
      checkStatus(
        await patch(`${baseUrl}/notifications/ready-for-integration`, {
          notificationIds: alertIds,
          hmsRegType,
        })
      );
    } catch (e) {
      handleError(e);
    }
  };
};
