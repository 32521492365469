import type { DropTargetMonitor, XYCoord } from 'react-dnd';

/* globals window document */
export const getCurrentWindowWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

class LocalStorageManager {
  static setRedirectUrlOnReturn(url) {
    localStorage.setItem('redirectOnReturn', url);
  }

  static getRedirectUrlOnReturn() {
    return localStorage.getItem('redirectOnReturn');
  }

  static removeRedirectUrlOnReturn() {
    localStorage.removeItem('redirectOnReturn');
  }

  static setMapLayersConfig(config) {
    localStorage.setItem('mapLayersConfig', JSON.stringify(config));
  }

  static getMapLayersConfig() {
    const c = localStorage.getItem('mapLayersConfig');
    if (c) {
      return JSON.parse(c);
    }
    return null;
  }
}

export { LocalStorageManager };

export const reduceToObjectById = <T extends { id: unknown }>(
  array: Array<T>,
  accessor?: keyof T
) => {
  const res: {
    [key: string]: T;
  } = {};

  for (let i = 0; i < array.length; i += 1) {
    const item = array[i];
    const key = item[accessor || 'id'] as string | number;
    res[key] = item;
  }

  return res;
};

export const filterFalsy = (arg: unknown) => !!arg;

export const searchParamsToObj = <T>(params: URLSearchParams) =>
  Object.fromEntries(params as Iterable<readonly string[]>) as T;

export const useDndHoverSorting =
  (
    ref: React.MutableRefObject<HTMLElement>,
    index: number,
    moveUser: (dragIndex: number, hoverIndex: number) => void
  ) =>
  <T>(item: T & { index: number }, monitor: DropTargetMonitor) => {
    if (!ref.current) {
      return;
    }

    const dragIndex = item.index;
    const hoverIndex = index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }
    // Determine rectangle on screen
    const hoverBoundingRect = ref.current?.getBoundingClientRect();
    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    // Determine mouse position
    const clientOffset = monitor.getClientOffset();
    // Get pixels to the top
    const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
    // Only perform the move when the mouse has crossed half of the items' height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }
    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    // eslint-disable-next-line no-param-reassign
    item.index = hoverIndex;

    // Time to actually perform the action
    moveUser(dragIndex, hoverIndex);
  };

export const getEnumNumericKeys = (
  enumForParsing: Record<number | string, unknown>,
  skipSorting = false
) =>
  (skipSorting ? Object.values : Object.keys)(enumForParsing)
    .map((key) => Number(key))
    .filter((key) => !Number.isNaN(key));
