/* eslint-disable react/no-array-index-key */
import React from 'react';
import { colors } from '@ditioas/ui-variables';

import * as Styled from '../commonStyles';

interface InlineInfoProps {
  label: string;
  value: React.ReactNode;
  multiline?: boolean;
  optional?: boolean;
  color?: keyof typeof colors;
  notRenderSemi?: boolean;
}

const InlineInfo = ({
  label,
  value,
  multiline,
  optional,
  color,
  notRenderSemi,
}: InlineInfoProps) => {
  if (
    (optional && !value) ||
    (typeof value === 'string' && value.length === 0)
  ) {
    return null;
  }

  return (
    <Styled.DetailContainer vertical={multiline}>
      <Styled.InlineText>
        {label}
        {!notRenderSemi ? ':' : ''}
      </Styled.InlineText>
      {multiline ? (
        <Styled.MultilineTextContainer>
          {(value as string).split('\n').map((line, index) => (
            <Styled.MultilineText key={`${label}-${index}`}>
              {line}
            </Styled.MultilineText>
          ))}
        </Styled.MultilineTextContainer>
      ) : (
        <Styled.InlineText color={color}>{value}</Styled.InlineText>
      )}
    </Styled.DetailContainer>
  );
};

export default InlineInfo;
