import { useMemo } from 'react';
import sortBy from 'lodash.sortby';
import type { SWRConfiguration } from 'swr';

import { useApi, useApiWithDataById, usePostSWRFetcher } from 'data/base-api';
import type {
  DetailedProject,
  Project,
  ProjectTask,
} from 'model/Projects/types';
import { filterAndSortTasks } from 'model/Projects/utils';

const baseUrl = '/api/v3/project';

export const useProjectTaskTree = (
  projId: string | undefined,
  revalidateOnMount = false
) => {
  const { data, ...rest } = useApi<ProjectTask[]>(
    projId ? `${baseUrl}/tasks/${projId}/tree` : null,
    {
      revalidateOnMount,
    }
  );

  const tasks = useMemo(() => {
    if (data) {
      return filterAndSortTasks(data);
    }

    return undefined;
  }, [data]);

  return {
    tasks,
    ...rest,
  };
};

export const useProjects = (companyId: string, config?: SWRConfiguration) => {
  const { data, ...rest } = useApiWithDataById<Project[], Project>(
    companyId ? `${baseUrl}/${companyId}` : null,
    config
  );

  const resultData = useMemo(() => {
    if (!data) return [];
    return sortBy(data, 'name');
  }, [data]);

  return {
    data: resultData,
    ...rest,
  };
};

export const useGetProjectsWithCompanies = (companyIds: string[]) => {
  const { data, ...rest } = usePostSWRFetcher(
    companyIds ? `${baseUrl}/company-ids/0` : null,
    companyIds
  );

  return {
    data,
    ...rest,
  };
};

export const useGetAllProjects = (
  activeOnly = true,
  userProjectsOnly = true,
  skip = false
) => {
  const params = new URLSearchParams();
  params.set('activeOnly', activeOnly.toString());
  params.set('userProjectsOnly', userProjectsOnly.toString());

  const { data, ...rest } = useApiWithDataById<
    DetailedProject[],
    DetailedProject
  >(skip ? null : `/api/projects/lookuplist/all?${params}`);

  return {
    data,
    ...rest,
  };
};

export const useGetProject = (id: string, callApi: boolean) => {
  const { data, ...rest } = useApi<DetailedProject>(
    callApi ? `/api/projects/${id}` : null
  );
  return {
    data,
    ...rest,
  };
};
